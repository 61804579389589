import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Badge, Row, Col, Container } from "react-bootstrap";

export default function MarketPlaceMenu(props, context) {
  const profile = useSelector((state) => state.ProfileState.data);

  const unreadNotificationsCount = (notificationTypes) => {
    const notifications =
      profile.notifications &&
      profile.notifications.filter(
        (notification) =>
          notification.unread && notificationTypes.includes(notification.type)
      );

    if (notifications && notifications.length > 0) {
      return notifications.length > 9 ? "+9" : notifications.length;
    }
  };

  return (
    <Container style={{ wordBreak: "break-word" }}>
      <Row className="mt-4">
        <Col xs={4}>
          <Link to="/resume" className="NavigationBar-icon">
            <i className="fa-solid fa-file-alt fa-2x resume"></i>
            <span> {context.t("Resume")} </span>
          </Link>
        </Col>
        {profile.resume && (
          <Fragment>
            <Col xs={4}>
              <Link to="/recruiter-roster" className="NavigationBar-icon">
                <i className="fa-solid fa-id-card fa-2x recruiter-listing"></i>
                <span> {context.t("Recruiters Listing")} </span>
              </Link>
            </Col>
            <Col xs={4}>
              <Link to="/employer-roster" className="NavigationBar-icon">
                <i className="fa-solid fa-briefcase fa-2x employer-listing"></i>
                <span> {context.t("Employers Listing")} </span>
              </Link>
            </Col>
          </Fragment>
        )}
        {["en", "mm-z", "mm-u", "kh", "ne"].includes(props.language) && (
          <Fragment>
            <Col xs={4} className="mt-3">
              <Link to="/jobs" className="NavigationBar-icon">
                <Badge
                  pill
                  variant="danger"
                  size="sm"
                  className="navigationItemCountBadge"
                >
                  {unreadNotificationsCount(["NEW_JOB_POSTED"])}
                </Badge>
                <i className="fa-solid fa-tools fa-2x job"></i>
                <span> {context.t("Jobs")} </span>
              </Link>
            </Col>
            <Col xs={4} className="mt-3">
              <Link to="/job-applications" className="NavigationBar-icon">
                <Badge
                  pill
                  variant="danger"
                  size="sm"
                  className="navigationItemCountBadge"
                >
                  {unreadNotificationsCount([
                    "APPLICATION_ACCEPTED",
                    "APPLICATION_DOWNLOADED",
                    "APPLICATION_REJECTED",
                    "APPLICATION_SHORTLISTED",
                    "APPLICATION_VIEWED",
                  ])}
                </Badge>
                <i className="fa-solid fa-file-signature fa-2x applications"></i>
                <span> {context.t("Applications")} </span>
              </Link>
            </Col>
          </Fragment>
        )}
      </Row>
    </Container>
  );
}

MarketPlaceMenu.contextTypes = {
  t: PropTypes.func.isRequired,
};
