import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FaFilter } from "react-icons/fa";
import { useModal, Modal } from "react-morphing-modal";
import "react-morphing-modal/dist/ReactMorphingModal.css";
import { Container, Row, Col } from "react-bootstrap";
import { getCountries } from "../../common/redux/actions/CountriesActions";
import Select from "react-select";
import { getIndustries } from "../../common/redux/actions/IndustriesActions";
import { getSubIndustries } from "../../common/redux/actions/SubIndustriesActions";
import "./FilterModal.css";

export default function FilterModal(props, context) {
  const dispatch = useDispatch();
  const { close, modalProps, getTriggerProps } = useModal({
    background: "white",
  });
  const countries = useSelector((state) => state.CountriesState);
  const type = props.type;
  const filterSorter = props.filterSorter;
  const handleFilterSorter = props.handleFilterSorter;

  const industries = useSelector((state) => state.IndustriesState);
  const subIndustries = useSelector((state) => state.SubIndustriesState);

  // React select dropdown options
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [industriesOptions, setIndustriesOptions] = useState();

  useEffect(() => {
    !countries.fetched && dispatch(getCountries());
    !industries.fetched && dispatch(getIndustries());
    !subIndustries.fetched && dispatch(getSubIndustries());
  }, []);

  // populate react select countries options
  useEffect(() => {
    if (countries.fetched) {
      const countriesArray = Object.keys(countries.items)
        .map((key) => countries.items[key])
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((item) => ({ value: item.country, label: item.name }));

      setCountriesOptions([
        { value: "", label: context.t("All countries") },
        ...countriesArray,
      ]);
    }
  }, [countries.items]);

  // industries options
  useEffect(() => {
    setIndustriesOptions(
      Object.values(industries.items).map((industry) => ({
        value: industry.industry,
        label: industry.name,
      }))
    );
  }, [industries]);

  // this is filter resetter
  const handleFilterSorterReset = () => {
    props.resethandler();
  };

  // here is reset button
  const resetButton = (handleFilterSorterReset) => {
    return (
      <Button
        className="reset-button"
        style={{ borderRadius: "60px", backgroundColor: "#49B3E0" }}
        onClick={handleFilterSorterReset}
      >
        <i className="fa-solid fa-filter-circle-xmark"></i>&nbsp;&nbsp;
        {context.t("Reset Filter")}
      </Button>
    );
  };

  // check the filter is default or not , if not default return to create resetButton
  const resetButtonChecker = (type, filterSorter) => {
    if (
      type === "employer" &&
      (filterSorter.country !== "" ||
        filterSorter.sort !== "-popularity" ||
        filterSorter.industry !== "")
    ) {
      return true;
    }
    if (
      type === "employer-roster" &&
      (filterSorter.country !== "" || filterSorter.sort !== "-popularity")
    ) {
      return true;
    }
    if (
      (type === "recruiter" || type === "recruiter-roster") &&
      (filterSorter.country !== "" || filterSorter.sort !== "-popularity")
    ) {
      return true;
    }
    if (
      type === "job" &&
      (filterSorter.country !== "" || filterSorter.sort !== "-created_at")
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Container className="fixed-top filter-container">
        <Row>
          <Col xs={12} lg={12} md={12}>
            <Form.Control
              focus="true"
              placeholder={context.t("Search...")}
              type="text"
              id="search-box"
              onChange={(e) =>
                handleFilterSorter({ ...filterSorter, search: e.target.value })
              }
              className="search-box"
            />
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            lg={resetButtonChecker(type, filterSorter) ? 6 : 12}
            md={12}
          >
            <Button
              className="filter-button"
              style={{
                borderRadius: "60px",
                backgroundColor: "whitesmoke",
                border: "1px solid gray",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                color: "black",
              }}
              {...getTriggerProps()}
            >
              <FaFilter />
              &nbsp;&nbsp;
              {context.t("Filter")}
            </Button>
          </Col>
          {resetButtonChecker(type, filterSorter) && (
            <Col xs={12} md={12} lg={6}>
              {resetButton(handleFilterSorterReset)}
            </Col>
          )}
        </Row>
      </Container>
      <Modal {...modalProps} closeButton={false} className="modal-panel">
        {type == "employer" && (
          <div className="section">
            <h5>{context.t("Select Industry")}</h5>
            <hr size="1" />
            <Select
              name="industry"
              placeholder={context.t("All industries")}
              value={
                filterSorter.industry
                  ? industriesOptions.find(
                      (option) => option.value === filterSorter.industry
                    )
                  : ""
              }
              options={industriesOptions}
              onChange={(data, event) =>
                handleFilterSorter({ ...filterSorter, industry: data.value })
              }
              isSearchable={false}
            />
          </div>
        )}

        {type != "job" && (
          <div className="section">
            {type == "recruiter-roster" || type == "employer-roster" ? null : (
              <div>
                <h5>{context.t("Select country")}</h5>
                <hr size="1" />
                {countriesOptions.map((country) => {
                  return (
                    <div key={country.value} className="mb-3">
                      <Form.Check
                        name="country"
                        type="radio"
                        value={country.value}
                        id={country.label}
                        label={country.label}
                        checked={filterSorter.country == country.value}
                        onChange={(e) =>
                          handleFilterSorter({
                            ...filterSorter,
                            country: e.target.value,
                          })
                        }
                      />
                    </div>
                  );
                })}
              </div>
            )}
            <h5>{context.t("Sort By")}</h5>
            <hr size="1" />
            <div key="radio-highest" className="mb-3">
              <Form.Check
                type="radio"
                id="radio-highest"
                label={context.t("Rating: Highest")}
                name="sort"
                value="-rating_score"
                checked={filterSorter.sort == "-rating_score"}
                onChange={(e) =>
                  handleFilterSorter({ ...filterSorter, sort: e.target.value })
                }
              />
            </div>
            <div key="radio-lowest" className="mb-3">
              <Form.Check
                type="radio"
                id="radio-lowest"
                label={context.t("Rating: Lowest")}
                name="sort"
                value="rating_score"
                checked={filterSorter.sort == "rating_score"}
                onChange={(e) =>
                  handleFilterSorter({ ...filterSorter, sort: e.target.value })
                }
              />
            </div>
            <div key="radio-popular" className="mb-3">
              <Form.Check
                type="radio"
                id="radio-popular"
                label={context.t("Popularity")}
                value="-popularity"
                name="sort"
                checked={filterSorter.sort == "-popularity"}
                onChange={(e) =>
                  handleFilterSorter({ ...filterSorter, sort: e.target.value })
                }
              />
            </div>
          </div>
        )}
        {type == "job" && (
          <div className="section">
            <h5>{context.t("Order By")}</h5>
            <hr size="1" />
            <div key="radio-newest" className="mb-3">
              <Form.Check
                type="radio"
                id="radio-newest"
                label={context.t("Posted: Newest")}
                value="-created_at"
                name="sort"
                checked={filterSorter.sort == "-created_at"}
                onChange={(e) =>
                  handleFilterSorter({ ...filterSorter, sort: e.target.value })
                }
              />
            </div>
            <div key="radio-oldest" className="mb-3">
              <Form.Check
                type="radio"
                id="radio-oldest"
                label={context.t("Posted: Oldest")}
                value="created_at"
                name="sort"
                checked={filterSorter.sort == "created_at"}
                onChange={(e) =>
                  handleFilterSorter({ ...filterSorter, sort: e.target.value })
                }
              />
            </div>
          </div>
        )}
        <hr size="1" />
        <center>
          {resetButtonChecker(type, filterSorter)
            ? resetButton(handleFilterSorterReset)
            : ""}
          <Button
            alt={context.t("Close")}
            style={{ backgroundColor: "wheat", color: "brown" }}
            className="close-button button-modified"
            onClick={close}
          >
            X
          </Button>
        </center>
      </Modal>
    </>
  );
}

FilterModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
